<template>
  <bob-dropdown-link
      :href="url"
  >
    {{$t(policyClass)}}
  </bob-dropdown-link>
</template>

<script lang="ts">
import VueI18n from 'vue-i18n';
import { addButtonTranslations } from '@/translations/addButton.translations';
import Vue from 'vue';

Vue.use(VueI18n);
export default {
  props: {
      url: {
        type: String,
        required: true,
      },
      policyClass: {
        type: String,
        required: true,
      },
  },
  i18n: new VueI18n({
    fallbackLocale: 'nl',
    locale: 'nl',
    messages: addButtonTranslations,
  }),
};
</script>
