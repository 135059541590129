<template>
  <div>
    <bob-dropdown variant="fab" @open="getLinks" icon="cross" data-e2e="thesis__add-button__bob-dropdown">
      {{ $t('create') }}
      <bob-dropdown-link v-if="loading">{{$t('loading')}}
      </bob-dropdown-link>
      <bob-dropdown-link v-else-if="error">{{$t('error')}}
      </bob-dropdown-link>
      <bob-dropdown-link
          v-else-if="nothingToCreate">
        {{$t('sorryNothingToCreate')}}
      </bob-dropdown-link>
      <template v-else>
        <bob-dropdown-link v-if="registration.show" :href="registration.url">
          {{$t('registration')}}
        </bob-dropdown-link>
        <policy
            v-for="policy in policiesToBeShown"
            :key="policy.policyClass"
            :url="policy.url"
            :policy-class="policy.policyClass"
        >
        </policy>
      </template>
    </bob-dropdown>
  </div>
</template>

<script lang="ts">
  import { environment } from '@/environment';
  import { DefinitionResponse } from '@/models/definition-response.interface';
  import { policies } from '@/models/policies.const';
  import { PolicyResponse } from '@/models/policy-response.interface';
  import { Status } from '@/models/status.enum';
  import { httpService } from '@/services/http-service';
  import { addButtonTranslations } from '@/translations/addButton.translations';
  import { Subdomain } from '@regas/bruce';
  import UserService, { User } from '@regas/user-service';
  import Vue from 'vue';
  import VueI18n from 'vue-i18n';
  import Policy from '../Policy.vue';

  Vue.use(VueI18n);
  export default {
    i18n: new VueI18n({
      fallbackLocale: 'nl',
      locale: 'nl',
      messages: addButtonTranslations,

    }),
    components: {
      Policy,
    },
    data() {
      return {
        initialized: {
          policies: false,
          registration: false,
        },
        policies,
        registration: {
          show: false,
          url: '',
        },
        Status,
        status: Status.Loading as Status,
        substitution: null,
      };
    },
    computed: {
      loading() {
        return this.status === Status.Loading;
      },
      error() {
        return this.status === Status.Error;
      },
      policiesToBeShown() {
        return this.policies.filter((policy) => policy.show);
      },
      nothingToCreate() {
        return this.status === Status.Loaded && (!this.registration.show && this.policiesToBeShown.length === 0);
      }
    },
    created(): void {
      UserService.state.then((user: User) => {
        this.$i18n.locale = user.internationalization.language;
        this.substitution = user.organisation.substitution;
      });
    },
    methods: {
      async getLinks() {
        this.status = Status.Loading;

        [await this.getPolicyLinks(), await this.getCreateRegistrationLink()]
        .reduce((promiseChain, currentTask) => {
          return promiseChain.then(() => currentTask);
          }, Promise.resolve().finally(() => {
            this.status = Status.Loaded;
        }));
      },
      async getPolicyLinks() {
        if (this.initialized.policies) {
          return;
        }

        this.initialized.policies = true;

        this.setPolicyUrls();

        const policyResponse = await this.fetchPolicies();
        this.showPolicyLinks(policyResponse);
      },
      showPolicyLinks(policyResponse: PolicyResponse[]) {
        policyResponse.forEach((policy) => {
          if (policy.create) {
            const policyIndex = this.policies.findIndex(
                ({policyClass}) => policyClass === policy.policyClass);

            if (policyIndex >= 0) {
              this.policies[policyIndex].show = true;
            }
          }
        });
      },
      async getCreateRegistrationLink() {
        if (this.initialized.registration) {
          return;
        }

        this.initialized.registration = true;

        this.setCreateRegistrationUrl();
        const definitions = await this.fetchDefinitions();

        this.registration.show = !!(definitions && definitions.length > 0);
      },
      async fetchPolicies(): Promise<PolicyResponse[]> {
        const responsePolicies = [];

        await Promise.all(
            policies.map(async (policy) => {
              const response = await httpService.fetch(
                  `${environment.origins[Subdomain.Api]}/authorization/policy/${policy.policyClass}`);
              responsePolicies.push(response);
            })  );

        return responsePolicies;
      },
      async fetchDefinitions(): Promise<DefinitionResponse[]> {
        return await httpService.fetch(`${environment.origins[Subdomain.Api]}/items/definitions`);
      },
      setPolicyUrls() {
        const baseUrl = `${environment.origins[Subdomain.Work]}/${this.substitution}/`;

        this.policies.forEach((policy, index) => {
          this.policies[index].url = `${baseUrl}${policy.urlEnd}&${this.returnUrl()}`;
        });
      },
      setCreateRegistrationUrl() {
        const url = `${environment.origins[Subdomain.Work]}/${this.substitution}/registration/create`;
        this.registration.url = `${url}?${this.returnUrl()}${this.addPersonId()}`;
      },
      returnUrl(): string { return `returnUrl=${encodeURIComponent(window.location.href)}`},

      addPersonId(): string {
        const parts = window.location.pathname.split('/');

        if(parts[1] === 'person-overview' && parts[2]){
          return `&participantId=${parts[2]}&participantType=person`
        }
        return ''
      },
    },
  };
</script>
